import React from "react"
import { graphql } from "gatsby"
import { AvForm, AvInput, AvGroup, AvFeedback } from "availity-reactstrap-validation"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { Container, Row, Col, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import styles from "../pages/css/index.module.css"


class ContactUs extends React.Component {
	constructor(props) {
		super(props);
		
		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
		this.state = {
			modal: false
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	handleValidSubmit(event, values) {
		this.setState({values});
		let data = new FormData();
		data.append('name', values.fullName);
		data.append('email', values.email);
		data.append('message', values.inquiry);
		data.append('form', values.formType);
		
		// fetch('https://apis.progressmfg.com/', {
		fetch('http://localhost:5000/', {
			method: 'POST',
			body: data
		}).then((resp) => {
			this.toggle();
		}).then((resp) => {
			this.form && this.form.reset();
		}).catch(err => console.log(err));
	}
	
	handleInvalidSubmit(event, errors, values) {
		this.setState({errors, values});
	}
	render() {
    const content = this.props.data.craft.content[0];

		return (
			<div>
				<Header/>
				<div className={styles.heroInterior}>
					<Container>
						<Row>
							<Col md={{ offset: 2, order: 2, size: 8 }} xs="12">
								<div className={styles.faqContent}>
									<h1>{content.contactHeadline}</h1>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={{ offset: 2, order: 2, size: 8 }} xs="12">
								<div className={styles.faqCard}>
									<AvForm className={styles.contactForm} onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit} name="bppContactForm" ref={c => (this.form = c)}>
										<div dangerouslySetInnerHTML={{ __html: content.contactContent }}></div>
										<AvGroup className={styles.halfInput}>
											<Label for="fullName">Full Name <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="text" name="fullName" id="fullName" required />
											<AvFeedback>Please enter your full name.</AvFeedback>
										</AvGroup>
										<AvGroup className={styles.halfInput}>
											<Label for="email">Email <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="email" name="email" id="email" required />
											<AvFeedback>Please enter a valid email.</AvFeedback>
										</AvGroup>
										<AvGroup className={styles.wholeInput}>
											<Label for="inquiry">Inquiry <span className={styles.requiredMark}>*</span>:</Label>
											<AvInput type="textarea" name="inquiry" id="inquiry" required/>
											<AvFeedback>Please enter your inquiry to submit form.</AvFeedback>
										</AvGroup>
										<AvGroup className={styles.wholeInput}>
											<AvInput type="hidden" name="formType" id="formType" required value="contact"/>
										</AvGroup>
										<Button className={styles.formSubmitButton}>Submit</Button>
									</AvForm>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer/>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader className={styles.modalHeadline} toggle={this.toggle}>{content.thankYouHeadline}</ModalHeader>
					<ModalBody dangerouslySetInnerHTML={{ __html: content.thankYouContent }}></ModalBody>
					<ModalFooter>
						<Button className={styles.formSubmitButton} onClick={this.toggle}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

export const query = graphql`
	query ContactPage {
		craft {
			content: entries(siteId: "4", slug: "contact-us") {
				... on craft_contactUs_contactUs_Entry {
					id
					contactHeadline
					contactContent 
					thankYouHeadline
					thankYouContent
				}
			}
		}
	}
`;

export default ContactUs